import React, { useRef } from 'react';

const CarouselIndicator = ({items}:any) => {
    let renderItems = useRef<Array<JSX.Element> | undefined>();
    console.log("renders CarouselIndicator")
    console.log("items itens:", items)
    
    if(items){
        renderItems.current = Array.from(Array(items).keys()).map((index:any) => {
            return(
              <button 
                className={`bg-secondary ${ index === 0 ? "active" : "" }`}  
                type="button" 
                data-bs-target="#carouselExampleIndicators" 
                data-bs-slide-to={index} 
                aria-current={`${ index === 0 ? true : false }`} 
                aria-label={`Slide ${index}`}
                key={index}>
              </button>
            );
        });
        console.log("items itens:", renderItems)
        return (
            <>
                {renderItems.current}
            </>
        );
    }else{
        return <h1>Carousel-Indicator...</h1>;
    }    
}

export default CarouselIndicator