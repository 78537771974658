import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SLIDSHOW_TYPE , DocumentoAdministrativo } from './store/SlidShowReducer';
import SlidShowDisplay from './SlidShowDisplay';
import CarouselIndicator from './CarouselIndicator';

const tipo_get_string = (tipo_number:number):string => {
  switch(tipo_number){
    case 5:
      return "Portaria da CMP";
    case 6:
      return "Ato da Presidência";
    default:
        return "Não listado";
  }
}

function App() {
  console.log("render App");
  const dispatch = useDispatch();
  const [slid_show_length, set_slid_show_length] = useState<Number | null>(0);

    useEffect(() => {    
    const loadData = async () => {
      let documento_administrativo_list: Array<DocumentoAdministrativo> = []
      let response

      // response = await fetch(`https://thingproxy.freeboard.io/fetch/https://sapl.parauapebas.pa.leg.br/api/protocoloadm/documentoadministrativo/?ano=2021&tipo=5&page_size=30&page=1&o=-data`);
      response = await fetch(`https://sapl.parauapebas.pa.leg.br/api/protocoloadm/documentoadministrativo/?ano=2021&tipo=5&page_size=30&page=1&o=-data`);
      if(response.ok){
        const data = await response.json();
    
        console.log(`api_load_data() | Documentos Administrativos Tipo 5 - data.results:`, data.results )
    
        const documento_administrativo_list_tmp: Array<DocumentoAdministrativo> = data.results.map((documento: any) => {
          return(
            {
              id: documento.id,
              ano: documento.ano,
              assunto: documento.assunto,
              data: documento.data,
              interessado: documento.interessado,
              link_texto_integral: documento.texto_integral,
              numero: documento.numero,
              str: documento.str,
              tipo: tipo_get_string(documento.tipo),
              ultima_edicao: documento.ultima_edicao,
          });
        });

        documento_administrativo_list = documento_administrativo_list.concat(documento_administrativo_list_tmp)
    
        console.log(`api_load_data() | Documentos Administrativos Tipo 5 - documento_administrativo_list:`,documento_administrativo_list);

      }

      // response = await fetch(`https://thingproxy.freeboard.io/fetch/https://sapl.parauapebas.pa.leg.br/api/protocoloadm/documentoadministrativo/?ano=2021&tipo=6&page_size=9&page=1&o=-data`);
      response = await fetch(`https://sapl.parauapebas.pa.leg.br/api/protocoloadm/documentoadministrativo/?ano=2021&tipo=6&page_size=9&page=1&o=-data`);
      if(response.ok){
        const data = await response.json();
    
        console.log(`api_load_data() | Documentos Administrativos Tipo 6 - data.results:`, data.results )
    
        const documento_administrativo_list_tmp: Array<DocumentoAdministrativo> = data.results.map((documento: any) => {
          return(
            {
              id: documento.id,
              ano: documento.ano,
              assunto: documento.assunto,
              data: documento.data,
              interessado: documento.interessado,
              link_texto_integral: documento.texto_integral,
              numero: documento.numero,
              str: documento.str,
              tipo: tipo_get_string(documento.tipo),
              ultima_edicao: documento.ultima_edicao,
          });
        });

        documento_administrativo_list = documento_administrativo_list.concat(documento_administrativo_list_tmp)
    
        console.log(`api_load_data() | Documentos Administrativos Tipo 6 - documento_administrativo_list:`,documento_administrativo_list);

      }

      console.log(`api_load_data() | Documentos Administrativos Tipo 5 e 6 - documento_administrativo_list:`,documento_administrativo_list);

      const n = 3 //Quantidade de Slids a serem exibidos
      
      const slid_show = new Array(Math.ceil(documento_administrativo_list.length / n)).fill(null).map(_ => documento_administrativo_list.splice(0, n))
      
      console.log(`api_load_data() | Documentos Administrativos Tipo 5 e 6 - slid_show:`,slid_show);

      set_slid_show_length(slid_show.length)

      dispatch({
        type: SLIDSHOW_TYPE,
        payload: slid_show
      })
    };
    loadData()
  }, [dispatch])
    
  return (
    <div className="App" >
      <header className="container d-flex flex-wrap justify-content-center py-3">
          <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <img src="/logo.svg" width="77" height="60" alt="Logo"/>
            <div className="d-block ms-1">
              <div className="fs-6">Câmara Municipal de</div>
              <div className="fs-3">Parauapebas</div>
            </div>
          </div>
          <div className="d-flex align-items-center me-0">
            <h2>
              Mural Eletrônico
            </h2>
          </div>
      </header>
      <div className="bg-primary p-3">
      </div>
      <main>
      <div>
        <div className="container border-bottom border-1">
          <p className="h2 py-1 m-0">
            Atos Administrativos
          </p>
        </div>
        <div className="container py-1">
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <SlidShowDisplay />
            </div>
            <div className="carousel-indicators position-relative">
              <CarouselIndicator items={slid_show_length} />
            </div>
          </div>
        </div>               
      </div>      
      </main>   
    </div>
  );
}

export default App;
