import React, { useRef } from 'react';
import {DataPTBR, DataTimePTBR} from './utilites/DataUtilites'

const SlidDisplay = ({items}:any) => {
    let renderItems = useRef<Array<JSX.Element> | undefined>();
    console.log("renders SlidDisplay")
    console.log("items itens:", items)
    
    if(items){
        renderItems.current = items.map((documento:any) => {
            return(
                <div className="carousel-item-row d-flex flex-row justify-content-between" key={documento.id}>
                    <div> 
                        <p className="fs-4 m-0">{documento.tipo} {documento.numero}/{documento.ano}</p>
                        <p className="m-0 lh-1 text-muted">
                            <small>
                            Disponibilizado em <strong>{DataPTBR(documento.data)}</strong>
                            </small>
                        </p>
                        <p className="m-0 lh-1 text-muted">
                            <small>
                            Atualizado em <strong>{DataTimePTBR(documento.ultima_edicao)}</strong>
                            </small>
                        </p>
                        <p className="m-0 lh-1 text-muted">
                            <small>
                            Interessado: <strong>{documento.interessado}</strong>
                            </small>
                        </p>                                      
                        <p className="lh-sm py-1 m-0">                
                            {documento.assunto}                  
                        </p>
                    </div>
                    <div>
                        <img className="" src={`https://chart.googleapis.com/chart?cht=qr&chl=${documento.link_texto_integral}&chs=140x140&choe=UTF-8&chld=L|2`} alt=""></img>
                    </div>        
                </div>
            );
        });
        return (
            <>
                {renderItems.current}
            </>
        );
    }else{
        return <h1>slid loading...</h1>;
    }

}

export default SlidDisplay