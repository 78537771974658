export const SLIDSHOW_TYPE = "SLIDSHOW_TYPE"

export interface DocumentoAdministrativo{
    id: number,
    ano: number,
    assunto: string,
    data: string,
    interessado: string,
    link_texto_integral: string,
    numero: number,
    str: string,
    ultima_edicao: string,
}

export interface Slid {
    slid: Array<DocumentoAdministrativo>
}

export interface SlidShow{
    slid_show: Array<Slid>
}

export interface SlidShowAction{
    type: string;
    payload: SlidShow | null;    
}

export const SlidShowReducer = (
    state: SlidShow | null = null, 
    action: SlidShowAction): 
    SlidShow | null => 
    {
        switch(action.type){
            case SLIDSHOW_TYPE:
                return action.payload;
            default:
                return state;
        }
}