import React, {useRef} from 'react';
import { AppState } from './store/AppState';
import { useSelector } from 'react-redux';
import SlidDisplay from './SlidDisplay';

const SlidShowDisplay = React.memo(() =>{
    
    let renderItems = useRef<Array<JSX.Element> | undefined>();

    console.log("renders SlidShowDisplay");

    const slidShow:any  = useSelector(
        (state: AppState) => state.slid_show
    );
    
    if(slidShow){
        
        console.log("SlidShow Array:",slidShow)

        renderItems.current = slidShow.map((items:any, index:any) => {
            return(
                <div className={`carousel-item ${ index === 0 ? "active" : "" }`} key={index}>
                    <SlidDisplay items={items}/>
                </div>
            );
        });
        
        return (
            <>
                {renderItems.current}
            </>
        );
    } else{
        return <h1>documento_administrativo_list loading...</h1>;
    }

});

export default SlidShowDisplay